// extracted by mini-css-extract-plugin
export var slideBar = "slidebar-module--slideBar--JV2om";
export var flipped = "slidebar-module--flipped--hp2Cc";
export var _0 = "slidebar-module--_0--MuxI7";
export var _1 = "slidebar-module--_1--uiiG3";
export var _2 = "slidebar-module--_2--HnUpu";
export var _3 = "slidebar-module--_3--+yuhV";
export var _4 = "slidebar-module--_4--Ghjpo";
export var _5 = "slidebar-module--_5--0v+jq";
export var _6 = "slidebar-module--_6--AASU7";
export var _7 = "slidebar-module--_7--eVzAP";
export var _8 = "slidebar-module--_8--4V5MR";
export var _9 = "slidebar-module--_9--ViC91";
export var _10 = "slidebar-module--_10--IvrAx";